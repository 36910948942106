import polyglotI18nProvider from 'ra-i18n-polyglot';
import fr from 'ra-language-french';
import {TranslationMessages} from 'react-admin';

import {PromotionSettingsType} from '../../api/generated';

const Admin = {
  name: 'administrateur |||| Administrateurs',
  fields: {
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'Adresse email',
    group: 'Groupe',
  },
};

const Retailer = {
  name: 'détaillant |||| Détaillants',
  fields: {
    address: 'Adresse du point de vente',
    cbsAgentId: 'Ref# CBS',
    id: 'ID détaillant',
    rdiRoleCode: 'ID détaillant',
    rdiPointOfSaleCode: 'ID point de vente',
    name: 'Nom du point de vente',
    status: 'Statut',
    departmentCodes: 'Codes Département',
    zipCodes: 'Codes postaux',
    departmentCode: 'Code département du point de vente',
    zipCode: 'Code postal du point de vente',
  },
};

const AppBasicContent = {
  name: 'contenu statique simple |||| Contenus statiques simples',
  fields: {
    type: 'Type',
    createdAt: 'Date de dernière modification',
    content: {
      title: 'Titre',
      html: 'Contenu HTML',
    },
  },
};

const CarrouselImage = {
  name: 'image du carrousel |||| Image du carrousel ',
  fields: {
    imageBytes: 'Vignette',
    description: 'Description',
    appearanceOrder: "Ordre d'apparition",
    offerType: "Type d'offre",
    internalUrl: 'URL',
    externalUrl: 'URL',
    name: 'Nom',
  },
};

const AppFaqContent = {
  name: 'FAQ |||| FAQ',
  fields: {
    content: {
      fields: {
        title: 'Titre',
        categories: {
          name: 'Catégories',
          fields: {
            title: 'Titre de la catégorie',
            description: 'Description de la catégorie',
            optionalIdentifier: 'Identifiant optionnel',
            posts: {
              fields: {
                title: "Titre de l'article",
                subtitle: "Sous-titre de l'article",
                content: "Contenu de l'article",
                postCategory: 'Catégorie',
                optionalIdentifier: 'Identifiant optionnel',
              },
            },
          },
        },
      },
    },
  },
};

const oauth = {
  encoding: {
    JSON: 'json',
    URL_FORM: 'x-www-form-urlencoded',
  },
};

const Product = {
  name: 'produit |||| Produits',
  fields: {
    cbsMerchantIdFormPage: 'Ref# du compte commerçant',
    cbsMerchantId: 'Ref# CBS',
    canExpire: 'Date de validité facture à contrôler si présente',
    canBePartiallyPaid: 'Paiement partiel autorisé',
    canBePaidByThirdParty: 'Paiement pour tiers autorisé',
    datamatrixProductId: 'ID produit',
    name: 'Libellé produit',
    'category.label': 'Catégorie produit',
    'category.collectionType': "Type d'encaissement",
    'partner.name': 'Libellé partenaire',
    'partner.id': 'Partenaire',
    'partner.datamatrixPartnerId': 'ID partenaire',
    active: 'Statut produit',
    createdAt: 'Date de création',
    createdAtGte: 'Date de création (de)',
    createdAtLte: 'Date de création (à)',
    minimumPaymentInCents: 'Montant minimum autorisé',
    maximumPaymentInCents: 'Montant maximum autorisé (frais de service inclus)',
    serviceFeeDescription: 'Description des frais de service',
    'category.serviceOffer.label': 'Rdi offre service',
    allowedPaymentMethods: 'Méthode de paiement',
    'category.id': 'Catégorie',
    apiUrl:
      "Adresse de l'API (endpoint) du partenaire pour vérifier l'état d’une opération dans son système",
    hookUrlVersion: 'Version des notifications',
    transactionNoticeUrl:
      "Adresse de l'API (endpoint) du partenaire pour lui notifier une transaction",
    authenticationUrl:
      "Adresse de l'API (endpoint) du partenaire d'authentification",
    authenticationMode: "Type d'authentification",
    credentialsUsername: 'Identifiant fourni par le partenaire',
    credentialsPassword: 'Mot de passe fourni par le partenaire',
    hasEncryptedCredentials: 'Mot de passe fourni par le partenaire',
    oauthBody: 'Body: Clé / Valeur',
    oauthBodyEncoding: 'Format des données',
  },
};

const PartnerAuth = {
  fields: {
    username: "Nom d'utilisateur",
    password: 'Mot de passe utilisateur',
  },
};

const Partner = {
  name: 'partenaire |||| Partenaires',
  fields: {
    name: 'Libellé',
    codeDetailLB: 'Code détail LB',
    datamatrixPartnerId: 'Identifiant',
    datamatrixSignatureConfig: 'Format datamatrix',
    products: Product.fields,
    partnerAuth: PartnerAuth.fields,
    createdAt: 'Date de création',
    isDisplayed: 'Visible',
    displayOrderInApp: "Ordre d'affichage",
    createdAtGte: 'Date de création (de)',
    createdAtLte: 'Date de création (à)',
  },
};

const Client = {
  name: 'client Nirio |||| Clients Nirio',
  fields: {
    id: 'ID SIP',
    createdAt: 'Date de création',
    createdAtGte: 'Date de création (de)',
    createdAtLte: 'Date de création (à)',
    email: 'Email',
    profile: 'Profil',
    // for detail view only
    homStatus: 'Homonymie',
    identityCertStatus: "Validité piece d'identité",
    gdaStatus: 'GDA',
    cbsClientId: 'Ref# CBS',
    status: 'Statut SIP',
    xpoAppUserId: 'Ref# XPO',
    payleadId: 'Ref# Paylead',
    nirioBankDebtCreatedAtGte: 'Date création dette/impayé (de)',
    nirioBankDebtCreatedAtLte: 'Date création dette/impayé (à)',
  },
};

const KycWorkflow = {
  fields: {
    cisDocumentId: 'Identifiant du document AriadNext',
    createdAt: 'Date de création',
    stage: 'Dernière étape',
    isManual: 'Vérification manuelle',
    verificationType: 'Type de vérification',
    manual: 'Manuelle',
    automatic: 'Automatique',
  },
};

const Payable = {
  name: 'facture |||| Factures',
  fields: {
    id: 'ID facture SIP',
    datamatrixContent: 'Datamatrix',
    partnerPayableId: 'ID facture partenaire',
    partnerCustomerId: 'ID client partenaire',
    payerId: 'ID client',
    category: 'Catégorie produit',
    partner: 'Partenaire',
    cbsTransactionId: 'Référence CBS',
    initialAmountInCents: 'Montant total (en €)',
    remainingAmountInCents: 'Montant restant à régler (en €)',
    payedAmount: 'Montant réglé (en €)',
    paymentStatus: 'Statut',
    startDate: "Date d'ajout (de)",
    endDate: "Date d'ajout (à)",
    createdAt: "Date d'ajout",
    expiresAt: 'Date et heure de fin de validité',
    currency: 'Devise',
    product: Product.fields,
  },
};

const NirioBankDebt = {
  name: 'Dettes et Impayés',
  fields: {
    createdAt: 'Date',
    cbsId: 'Numéro de la transaction',
    operationType: "Type d'opération",
    origin: 'Origine',
    amountInCents: 'Montant initial',
    remainingAmountInCents: 'Montant dû',
    paidAt: 'Recouvrement',
  },
};

const CessationRaWorkflow = {
  name: "Cessation de la relation d'affaire",
  fields: {
    createdAt: 'Date',
    source: 'Source',
    initiative: 'Initiateur',
    iban: 'Iban',
    reason: 'Raison',
    reasonComment: 'Précisions',
    xpoReason: 'Raison XPO',
    isDowngrade: 'Type de fermeture',
  },
};

const Cart = {
  name: 'Panier |||| Paniers',
  fields: {
    id: 'ID panier',
    idPayable: 'ID Facture',
    createdAt: 'Date création',
    updatedAt: 'Date mise à jour',
    paidAt: "Date de l'opération",
    payerId: 'ID client SIP',
    status: 'Statut panier',
    paymentId: 'ID opération',
    createdAtStartDate: 'Date de création (de)',
    createdAtEndDate: 'Date de création (à)',
    totalAmount: 'Montant (en €)',
    initialAmountInCents: 'Montant total de la facture (en €)',
    remainingAmountInCents: 'Montant restant à régler (en €)',
    client: Client.fields,
    operation: {id: 'ID opération SIP'},
    item: {payable: Payable.fields, amountInCents: 'Montant (en €)'},
  },
} as const;

const Operation = {
  name: 'opération |||| Opérations',
  fields: {
    id: 'ID opération SIP',
    cbsClientId: 'Ref# CBS',
    createdAt: 'Date de création',
    status: "Statut de l'opération",
    paymentMethod: 'Opération réalisée en',
    amountInCents: "Montant de l'opération (en €)",
    cart: Cart.fields,
    retailer: Retailer.fields,
    product: Product.fields,
  },
};

const NirioBankWelcomeCode = {
  name: 'code promotionnel |||| Codes promotionnels',
  fields: {
    id: 'ID',
    code: 'Code',
    maxClaimed: "Nombre max d'utilisations",
    createdAt: 'Date de création',
  },
};

const NirioBankWelcomePromotion = {
  name: 'promotion de bienvenue |||| Promotions de bienvenue',
  fields: {
    id: 'ID',
    name: 'Nom',
    isActive: 'Statut',
    type: "Type d'avantage",
    dateStart: 'Date de début (de)',
    dateEnd: 'Date de fin (à)',
    requiredPaymentsCount: 'Nombre de paiements requis',
    requiredPaymentsMinimumAmountInCents:
      'Montant minimum des paiements requis (en €)',
    requiredPaymentsSlidingDays: 'Période glissante requise (en jours)',
    gainAmountInCents: 'Montant gagné (en €)',
    freeMonthsCount: 'Nombre de mois offerts',
    offers: 'Offres',
    createdAt: 'Date de création',
    welcomeCodes: NirioBankWelcomeCode.fields,
  },
  invite: 'Voulez-vous en créer une ?',
};

const PromotionSettings = {
  name: 'promotion |||| Promotions',
  fields: {
    id: 'Type de promotion',
    isActive: 'Statut',
    userLimit: "Limite d'utilisateurs",
    slidingMonthLimit: 'Nombre de mois glissants',
    requiredPaymentsCount: 'Nombre de paiements requis',
    requiredPaymentsMinimumAmountInCents:
      'Montant minimum des paiements requis (en €)',
    requiredPaymentsSlidingDays:
      'Nombre de paiements requis dans la période glissante requise (en jours)',
    gainAmountInCents: 'Montant gagné par le filleul (en €)',
    retailerGainAmountInCents: 'Montant gagné par le détaillant (en €)',
    referrerGainAmountInCents: 'Montant gagné par le parrain (en €)',
    [PromotionSettingsType.ReferrerPromotion]: 'Promotion de parrainage',
    [PromotionSettingsType.RetailerPromotion]: 'Promotion des détaillants',
  },
};

const MaintenanceSettings = {
  name: 'Paramètres de maintenance',
  fields: {
    go: 'Go',
    premio: 'Premio',
    message: 'Message',
  },
};

const messages: {[key: string]: TranslationMessages} = {
  fr: {
    ...fr,
    oauth,
    ra: {
      ...fr.ra,
      action: {
        ...fr.ra.action,
        edit: 'Modifier',
        clear_array_input: 'Effacer le tableau', // eslint-disable-line camelcase
      },
      message: {
        ...fr.ra.message,
        // eslint-disable-next-line camelcase
        clear_array_input:
          'Attention, toutes les valeurs du tableau seront supprimées.',
      },
      page: {
        ...fr.ra.page,
        edit: 'Modifier %{name} %{recordRepresentation}',
      },
      notification: {
        ...fr.ra.notification,
        downloadPdfError: 'Une erreur a eu lieu lors du téléchargement du pdf.',
        passwordCopiedToClipboard: 'Mot de passe copié dans le presse-papier',
        partnerCreateError:
          "Une erreur a eu lieu lors de la création du partenaire. Vérifiez l'unicité des identifiants",
        partnerEditError:
          "Une erreur a eu lieu lors de l'édition du partenaire. Vérifiez l'unicité des identifiants",
        welcomeCodeCreateError:
          "Une erreur a eu lieu lors de la création du code promotionnel. Vérifiez l'unicité du code",
        promotionSettingsEditError:
          "Une erreur a eu lieu lors de l'édition de la promotion. Vérifiez l'unicité de la promotion",
      },
    },
    resources: {
      Admin,
      Retailer,
      AppBasicContent,
      AppFaqContent,
      CarrouselImage,
      Product,
      Partner,
      Client,
      KycWorkflow,
      Operation,
      Cart,
      Payable,
      NirioBankDebt,
      NirioBankWelcomePromotion,
      NirioBankWelcomeCode,
      PromotionSettings,
      MaintenanceSettings,
      CessationRaWorkflow,
    },
  },
};

export const i18nProvider = polyglotI18nProvider(
  locale => messages[locale],
  'fr',
);
